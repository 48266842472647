import React from 'react'
import { Helmet } from 'react-helmet'
import Header from '../components/Header'
import siteIcon from '../assets/icons/SS_192x192.png'
import '../css/style.scss';

const IndexPage = () => {
	return (
		<section>
			<Helmet>
				<html lang="sv" amp />
				<meta charSet="utf-8" />
				<title>Stefan Sjöman</title>
				<meta name="description" content="Stefan Sjöman CV Portfolio Frontend Developer" />
				<link rel="canonical" href="https://stefansjoman.se" />
				<link rel="icon" href={siteIcon} />
			</Helmet>
			<Header alignCenter={"header-center"} />
		</section>
	)
}

export default IndexPage